import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { AsideInfo } from "../components/aside/aside"
import { StaticImage } from "gatsby-plugin-image"

const ContactPage = () => {
  return (
    <Layout>
      <Seo
        title="Contact us"
        description="Contact us today for some of the best greek food you'll ever have."
        canonical="https://papagreek.com/contact/"
      />

      <div className="siteWidth">
        <StaticImage
          src="../images/heros/home/homeHero_slideA.png"
          loading="eager"
          layout="fullWidth"
        />
      </div>
      <div className="contentWidth grid">
        <div className="gridA">
          <h1 className="heading">Contact Us</h1>
          <p>
            Customer feedback is the lifeblood of our business. Tell us what’s
            on your mind, good or bad.
          </p>

          <p>
            We respond to all customer feedback and look forward to hearing from
            you!
          </p>
          <p>Email us at PapaGreek1@gmail.com</p>
        </div>
        <div className="gridB">
          <AsideInfo />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

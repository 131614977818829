import * as React from "react"

export const AsideInfo = () => {
  return (
    <>
      <div className="aside_wrapper">
        <h2>Burnaby</h2>
        <p>
          Unit 110,
          <br />
          7515 Market Crossing
          <br />
          Burnaby, B.C.
          <br />
          (604) 451-4060
        </p>
        <h2>Abbotsford</h2>
        <p>
          Unit D-120,
          <br />
          3122 MOUNT LEHMAN RD
          <br />
          Abbotsford, B.C.
          <br />
          (604) 852-9676
        </p>
        <h2>Hours</h2>
        <p>
          Mon: 11:00AM - 8:00PM
          <br />
          Tue: 11:00AM - 8:00PM
          <br />
          Wed : 11:00AM - 8:00PM
          <br />
          Thu: 11:00AM - 8:00PM
          <br />
          Fri 11:00AM - 9:00PM
          <br />
          Sat: 11:00AM - 9:00PM
          <br />
          Sun: 11:00AM - 8:00PM
        </p>
      </div>
    </>
  )
}
